<template>
  <b-card body-class="noPadingTop">
    <!-- form -->
    <b-form class="">
      <b-row>
        <!-- Comments -->
        <b-col cols="4">
          <b-form-group
            label="Area Suggested"
            label-for="Area Suggested"
          >
            <v-select
              v-model="candidate.CandidateData.AreaSuggested"
              :options="getDataAreaSuggested"
              label="Name"
              input-id="payment-method"
              class="invoice-filter-select"
              placeholder="Select Area"
              :clearable="true"
            />
          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group
            label="Interview Date"
            label-for="Interview Date"
          >
            <flat-pickr
              v-model="candidate.CandidateData.Interview.InterviewDate"
              class="form-control"
              name="date"
              placeholder="Interview Date"
            />
          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group
            label="Interview By"
            label-for="Interview By"
          >
            <v-select
              v-model="candidate.CandidateData.Interview.InterviewBy"
              :options="getDataSupervisors"
              label="Name"
              input-id="payment-method"
              class="invoice-filter-select"
              placeholder="Interview By"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <b-form-group
            label="Interview Comments"
            label-for="Interview Comments"
          >
            <b-form-textarea
              v-model="candidate.CandidateData.Interview.InterviewComments"
              rows="4"
              placeholder="Your Interview Comments here..."
            />
          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group
            label="English Level"
            label-for="English Level"
          >
            <v-select
              v-model="candidate.CandidateData.EnglishLevel"
              :options="getDataEnglishLevel"
              label="Name"
              input-id="payment-method"
              class="invoice-filter-select"
              placeholder="English Level"
            />
          </b-form-group>
          <b-form-group
            label="Typing wpm / %Accu"
            label-for="Typing wpm / %Accu"
          >
            <b-form-input
              v-model="candidate.CandidateData.TypingTest"
              name="Typing wpm / %Accu"
              placeholder="Typing wpm / %Accu"
            />
          </b-form-group>
        </b-col>
        <b-col sm="8">
          <b-form-group
            label="English Comments"
            label-for="English Comments"
          >
            <b-form-textarea
              v-model="candidate.CandidateData.EnglishLevel.EnglishComments"
              rows="4"
              placeholder="Your English Comments here..."
            />
          </b-form-group>
        </b-col>
        <b-col sm="2">
          <b-form-group
            label="Computer Test"
            label-for="Computer Test"
          >
            <b-form-checkbox
              v-model="candidate.CandidateData.ComputerTest"
              class="custom-control-primary"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col sm="">
          <b-form-group
            label="Prefered Salary"
            label-for="Prefered Salary"
          >
            <b-form-input
              v-model="candidate.CandidateData.PreferedSalary"
              name="Prefered Salary"
              placeholder="Prefered Salary"
            />
          </b-form-group>
        </b-col>
        <!-- <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
          >
            Save changes
          </b-button>
        </b-col> -->
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BFormTextarea, BFormCheckbox,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

export default {
  components: {
    // BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BRow,
    BCol,
    BCard,
    flatPickr,
    vSelect,
    BFormCheckbox,
  },
  directives: {
  },
  props: {
    candidate: {
      type: Object,
      required: true,
    },
    getDataAreaSuggested: {
      type: Array,
      required: true,
    },
    getDataSupervisors: {
      type: Array,
      required: true,
    },
    getDataEnglishLevel: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'US',
        },
      },
      TypeInterviewBy: [
        { Name: 'Jorge' },
        { Name: 'Fernando' },
        { Name: 'Mayra' },
      ],
      TypeComputerTest: [
        { Id: '1', Name: 'Pass' },
        { Id: '0', Name: 'Fail' },
      ],
    }
  },
  methods: {
  },
}
</script>
<style>
.noPadingTop{
  padding-top: 0px;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
