<template>
  <b-card body-class="noPadingTop">
    <!-- form -->
    <b-form class="">
      <b-row>
        <!-- Comments -->
        <b-col cols="12">
          <b-form-group
            label="Special Comments"
            label-for="Special Comments"
          >
            <b-form-textarea
              v-model="candidate.CandidateData.SpecialComments"
              rows="4"
              placeholder="Your Comments here..."
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Date Contacted"
            label-for="Date Contacted"
          >
            <flat-pickr
              v-model="candidate.CandidateData.DateToContact"
              class="form-control"
              name="date"
              placeholder="Date To Contact"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Date Contacted"
            label-for="Date Contacted"
          >
            <flat-pickr
              v-model="candidate.CandidateData.DateContacted"
              class="form-control"
              name="date"
              placeholder="Date Contacted"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="CV Folder Link"
            label-for="CV Folder Link"
          >
            <b-input-group>
              <b-input-group-prepend
                is-text
                @click="getFile(candidate.CandidateData.CvLink)"
              >
                <feather-icon icon="DownloadIcon" />
              </b-input-group-prepend>
              <b-form-file
                v-model="cvFile"
                :placeholder="candidate.CandidateData.CvLink ? candidate.CandidateData.CvLink.replace('./docs/', '') : 'Choose a file or drop it here...'"
                drop-placeholder="Drop file here..."
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <b-form-group
            label="CC Experience"
            label-for="CC Experience"
          >
            <b-form-checkbox
              v-model="candidate.CandidateData.CcExperience"
              class="custom-control-primary"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <!-- <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
          >
            Save changes
          </b-button>
        </b-col> -->
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BForm, BFormGroup, BRow, BCol, BCard, BInputGroupPrepend, BInputGroup, BFormCheckbox, BFormTextarea, BFormFile,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import userService from '@core/services/users/usersService'

export default {
  components: {
    // BButton,
    BForm,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormCheckbox,
    BFormTextarea,
    BRow,
    BCol,
    BCard,
    flatPickr,
    BFormFile,
  },
  directives: {
  },
  props: {
    candidate: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      cvFile: [],
      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'US',
        },
      },
      TypeofGender: [
        { Name: 'Male' },
        { Name: 'Female' },
      ],
      TypeofCovid19VACC: [
        { Name: 'Yes' },
        { Name: 'No' },
        { Name: '#@$%"¡' },
      ],
    }
  },
  watch: {
    cvFile(file) {
      if (!file) return
      this.candidate.CandidateData.CvLink = file.name
      this.candidate.CandidateData.cvFile = file
    },
  },
  methods: {
    getFile(url) {
      if (!url) return
      const fullPath = userService.getUrlFile(url.replace('./', '/'))
      window.open(fullPath, '_blank')
    },
  },
}
</script>
<style>
.noPadingTop{
  padding-top: 0px;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
