<template>
  <b-card body-class="noPadingTop">
    <!-- form -->
    <b-form class="">
      <b-row>
        <!-- Comments -->
        <b-col cols="6">
          <b-form-group
            label="Process end Date"
            label-for="Process end Date"
          >
            <flat-pickr
              v-model="candidate.CandidateData.ProcessEndDate"
              class="form-control"
              name="date"
              placeholder="Process end Date"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Final Interview Date"
            label-for="Final Interview Date"
          >
            <flat-pickr
              v-model="candidate.CandidateData.FinalInterviewDate"
              class="form-control"
              name="date"
              placeholder="Interview Date"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Interview By"
            label-for="Interview By"
          >
            <v-select
              v-model="candidate.CandidateData.InterviewValidator"
              :options="getDataInterviewValidator"
              label="Name"
              input-id="payment-method"
              class="invoice-filter-select"
              placeholder="Interview By"
            />
          </b-form-group>
        </b-col>
        <!-- <b-col sm="6">
          <b-form-group
            label="Interview Comments"
            label-for="Interview Comments"
          >
            <b-form-textarea
              rows="4"
              placeholder="Your Interview Comments here..."
            />
          </b-form-group>
        </b-col> -->
        <!-- <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
          >
            Save changes
          </b-button>
        </b-col> -->
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BForm, BFormGroup, BRow, BCol, BCard, // BFormTextarea,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

export default {
  components: {
    // BButton,
    BForm,
    BFormGroup,
    // BFormTextarea,
    BRow,
    BCol,
    BCard,
    flatPickr,
    vSelect,
  },
  directives: {
  },
  props: {
    candidate: {
      type: Object,
      required: true,
    },
    getDataInterviewValidator: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'US',
        },
      },
      TypeInterviewBy: [
        { Name: 'Jorge' },
        { Name: 'Fernando' },
        { Name: 'Mayra' },
      ],
      TypeComputerTest: [
        { Id: '1', Name: 'Pass' },
        { Id: '0', Name: 'Fail' },
      ],
    }
  },
  methods: {
  },
}
</script>
<style>
.noPadingTop{
  padding-top: 0px;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
