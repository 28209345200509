<template>
  <div>
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-card>
        <b-row>
          <b-col
            cols="12"
            xl="9"
            lg="8"
            md="7"
          >
            <b-card>

              <b-row>

                <!-- User Info: Left col -->
                <b-col
                  cols="21"
                  xl="3"
                  class="d-flex justify-content-between flex-column"
                >
                  <!-- User Avatar & Action Buttons -->
                  <div class="d-flex justify-content-start">
                    <div class="d-flex flex-column">
                      <div class="mb-1">
                        <h4 class="mb-0">
                          {{ candidate.NameUser }}
                        </h4>
                        <span class="card-text">{{ candidate.EmailUser }}</span>
                      </div>
                      <div class="d-flex align-items-center">
                        <b-avatar
                          variant="light-success"
                          rounded
                        >
                          <feather-icon
                            icon="UserIcon"
                            size="18"
                          />
                        </b-avatar>
                        <div class="ml-1">
                          <h5 class="mb-0">
                            {{ candidate.CandidateData.Gender.Name }}
                          </h5>
                          <small>Gender</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-col>

                <!-- Right Col: Table -->
                <b-col
                  cols="12"
                  xl="6"
                >
                  <b-form-group
                    label="Source"
                    label-for="Source"
                  >
                    <v-select
                      v-model="candidate.CandidateData.Source"
                      :options="DataSource"
                      label="Name"
                      input-id="payment-method"
                      class="invoice-filter-select"
                      placeholder="Select Source"
                      :clearable="true"
                    />
                  </b-form-group>
                  <b-row>
                    <b-col
                      cols="12"
                      xl="7"
                    >
                      <table class="mt-2 mt-xl-0 w-100">
                        <tr>
                          <th class="pb-50">
                            <feather-icon
                              icon="CheckIcon"
                              class="mr-75"
                            />
                            <span class="font-weight-bold">Status</span>
                          </th>
                          <td class="pb-50 text-capitalize">
                            {{ candidate.CandidateData.AppStatus.Name }}
                          </td>
                        </tr>
                        <tr>
                          <th class="pb-50">
                            <feather-icon
                              icon="CalendarIcon"
                              class="mr-75"
                            />
                            <span class="font-weight-bold">DOB</span>
                          </th>
                          <td class="pb-50">
                            {{ candidate.CandidateData.Dob | date-format }}
                          </td>
                        </tr>
                        <tr>
                          <th class="pb-50">
                            <feather-icon
                              icon="FlagIcon"
                              class="mr-75"
                            />
                            <span class="font-weight-bold">Age</span>
                          </th>
                          <td class="pb-50">
                            {{ candidate.CandidateData.Dob | age-format }}
                          </td>
                        </tr>
                        <tr>
                          <th>
                            <feather-icon
                              icon="PhoneIcon"
                              class="mr-75"
                            />
                            <span class="font-weight-bold">Contact</span>
                          </th>
                          <td>
                            {{ candidate.CandidateData.Phone }}
                          </td>
                        </tr>
                      </table>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col
            cols="12"
            md="5"
            xl="3"
            lg="4"
          >
            <div
              class="d-flex flex-wrap"
              style="justify-content: end;"
            >
              <b-button
                variant="primary"
                @click="SaveCandidateProfile"
              >
                Save Changes
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-tabs
          vertical
          content-class="col-12 col-md-9 mt-1 mt-md-0"
          pills
          nav-wrapper-class="col-md-3 col-12"
          nav-class="nav-left"
        >

          <!-- general tab -->
          <b-tab active>

            <!-- title -->
            <template #title>
              <feather-icon
                icon="UserIcon"
                size="18"
                class="mr-50"
              />
              <span class="font-weight-bold">Personal</span>
            </template>

            <candidate-personal
              :candidate="candidate"
              :get-data-gender="DataGender"
              :get-data-covid-vacc="DataCovidVacc"
            />
          </b-tab>
          <!--/ general tab -->

          <!-- change password tab -->
          <b-tab>

            <!-- title -->
            <template #title>
              <feather-icon
                icon="EyeIcon"
                size="18"
                class="mr-50"
              />
              <span class="font-weight-bold">First Aproach</span>
            </template>

            <candidate-first-aproach
              :candidate="candidate"
            />
          </b-tab>
          <!--/ change password tab -->

          <!-- info -->
          <b-tab>

            <!-- title -->
            <template #title>
              <feather-icon
                icon="FileTextIcon"
                size="18"
                class="mr-50"
              />
              <span class="font-weight-bold">Interview</span>
            </template>

            <candidate-interview
              :candidate="candidate"
              :get-data-area-suggested="DataAreaSuggested"
              :get-data-supervisors="Supervisors"
              :get-data-english-level="DataEnglishLevel"
            />
          </b-tab>

          <!-- social links -->
          <b-tab>

            <!-- title -->
            <template #title>
              <feather-icon
                icon="FilterIcon"
                size="18"
                class="mr-50"
              />
              <span class="font-weight-bold">Final Process</span>
            </template>

            <candidate-final-process
              :candidate="candidate"
              :get-data-interview-validator="DataInterviewValidator"
            />
          </b-tab>
        </b-tabs>
      </b-card>
    </b-overlay>
  </div>

</template>

<script>
import {
  BTabs, BTab, BCard, BButton, BAvatar, BRow, BCol, BFormGroup, BOverlay,
} from 'bootstrap-vue'
import authService from '@core/services/auth/authService'
import catalogsServices from '@core/services/catalogs/catalogsService'
import profileServices from '@core/services/profiles/profileService'
import vSelect from 'vue-select'
import Swal from 'sweetalert2'
import CandidatePersonal from './CandidatePersonal.vue'
import CandidateFirstAproach from './CandidateFirstAproach.vue'
import CandidateInterview from './CandidateInterview.vue'
import CandidateFinalProcess from './CandidateFinalProcess.vue'

export default {
  components: {
    BButton,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BCard,
    BTabs,
    BTab,
    CandidatePersonal,
    CandidateFirstAproach,
    CandidateInterview,
    CandidateFinalProcess,
    vSelect,
    BOverlay,
  },
  data() {
    return {
      show: false,
      options: {},
      candidate: {},
      Catalogs: [
        'gender', 'applicationStatus', 'covidVacc', 'source', 'areaSuggested', 'englishLevel', 'interviewValidator',
      ],
      DataGender: [],
      DataApplicationStatus: [],
      DataCovidVacc: [],
      DataSource: [],
      DataAreaSuggested: [],
      DataEnglishLevel: [],
      DataInterviewValidator: [],
      // Options Selected
      SelectedSource: null,
      Supervisors: [],
      IdCandidateSelected: 0,
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.IdCandidateSelected = this.$store.getters['profiles/getIdCandidate']
      this.getCandidateData(this.IdCandidateSelected)
      this.getAllCatalogs()
      this.getAllSupervisors()
    },
    getCandidateData(id) {
      authService.getUserById({
        id,
      }).then(response => {
        this.candidate = response.data.user
      })
    },
    getAllCatalogs() {
      this.Catalogs.forEach(catalog => {
        this.getSpecificCatalog(catalog)
      })
    },
    getAllSupervisors() {
      profileServices.getAllSupervisor({}).then(response => {
        this.Supervisors = response.data.data
      })
    },
    /* eslint-disable */
    getSpecificCatalog(catalog) {
      catalogsServices.getAllElementCatalog({
        table: catalog,
      }).then(response => {
        const data = response.data.data
        switch (catalog) {
          case 'gender':
            this.DataGender = data ? data : {}
            break;
          case 'applicationStatus':
            this.DataApplicationStatus = data
            break;
          case 'covidVacc':
            this.DataCovidVacc = data
            break;
          case 'source':
            this.DataSource = data
            break;
          case 'areaSuggested':
            this.DataAreaSuggested = data
            break;
          case 'englishLevel':
            this.DataEnglishLevel = data
            break;
          case 'interviewValidator':
            this.DataInterviewValidator = data
            break;        
          default:
            break;
        }
      })
    },

    SaveCandidateProfile() {
      this.show = true
      setTimeout(()=>{
        var formData = new FormData();
        if(this.candidate.CandidateData.cvFile){
          formData.append("cv", this.candidate.CandidateData.cvFile);
        }
        formData.append("candidate",  JSON.stringify(this.candidate));
        authService.updateCandidate(formData).then(() => {
          this.show = false
          Swal.fire(
            'Success',
            'Candidate Save!',
            'success',
          )
          this.$router.go()
        }).catch(error => {
            this.show = false
            this.toastErrorHandler(error)
          })
      }, 1000);
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
