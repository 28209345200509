<template>
  <b-card body-class="noPadingTop">
    <!-- form -->
    <b-form class="">
      <b-row>
        <b-col sm="6">
          <b-form-group
            label="FirstName"
            label-for="FirstName"
          >
            <b-form-input
              v-model="candidate.FirstName"
              placeholder="FirstName"
              name="FirstName"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="LastName"
            label-for="LastName"
          >
            <b-form-input
              v-model="candidate.LastName"
              name="LastName"
              placeholder="LastName"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="E-mail"
            label-for="account-e-mail"
          >
            <b-form-input
              v-model="candidate.EmailUser"
              name="email"
              placeholder="Email"
            />

          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Phone"
            label-for="Phone"
          >
            <b-input-group>
              <b-input-group-prepend is-text>
                US (+1)
              </b-input-group-prepend>
              <cleave
                id="phone"
                v-model="candidate.CandidateData.Phone"
                class="form-control"
                :raw="false"
                :options="options.phone"
                placeholder="1234 567 8900"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group
            label="DOB"
            label-for="DOB"
          >
            <flat-pickr
              v-model="candidate.CandidateData.Dob"
              class="form-control"
              name="DOB"
              placeholder="DOB"
            />

          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group
            label="Gender"
            label-for="Gender"
          >
            <v-select
              v-model="candidate.CandidateData.Gender"
              :options="getDataGender"
              label="Name"
              input-id="payment-method"
              class="invoice-filter-select"
              placeholder="Select Gender"
              :clearable="true"
            />
          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group
            label="Covid 19 VACC"
            label-for="Covid"
          >
            <v-select
              v-model="candidate.CandidateData.Covid"
              :options="getDataCovidVacc"
              label="Name"
              input-id="payment-method"
              class="invoice-filter-select"
              placeholder="Select Covid-19 VACC"
              :clearable="true"
            />
          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group
            label="Travel Visa"
            label-for="Travel Visa"
          >
            <b-form-checkbox
              v-model="candidate.CandidateData.TravelVisa"
              class="custom-control-primary"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <!-- <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
          >
            Save changes
          </b-button>
        </b-col> -->
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroupPrepend, BInputGroup, BFormCheckbox,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    // BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroup,
    BFormCheckbox,
    BRow,
    BCol,
    BCard,
    Cleave,
    vSelect,
    flatPickr,
  },
  directives: {
  },
  props: {
    candidate: {
      type: Object,
      required: true,
    },
    getDataGender: {
      type: Array,
      required: true,
    },
    getDataCovidVacc: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'US',
        },
      },
      TypeofGender: [],
      TypeofCovid19VACC: [],
    }
  },
  methods: {
  },
}
</script>
<style>
.noPadingTop{
  padding-top: 0px;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
